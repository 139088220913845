/* eslint-disable */
import HttpService from '../HttpService';
import {loadDependentsByBeneficiaryCpf} from "@/common/contract-service";
export default class ContractService {
  constructor() {
    this._httpService = new HttpService(`contract`);
  }

  FindAllDependentAndContractPlanByBeneficiaryId(beneficiaryId){
    return this._httpService.get(`/dependent/findAllAndContractedPlanByBeneficiaryId/${ beneficiaryId }`);
  }

  async CreateTelemedicineDependentFromHolder(id, data){
    return await this._httpService.post(`/beneficiary/${id}/subscription`, data);
  }

  async FindAllByBeneficiaryId(beneficiaryId) {
    return this._httpService.get('/dependent/findAllByBeneficiaryId/' + beneficiaryId);
  }

  async CancelTelemedicineDependent(id) {
    return await this._httpService.delete(`/dependent/${id}/contracted-plan/telemedicine`);
  }

  async GenerateUserReportByStatus(page, size, financialGroup) {
    return await this._httpService.get(`/beneficiary_contracted_plan/generateReportByStatus?page=${page}&size=${size}${financialGroup ? '&financialGroupId='+financialGroup : ''}`);
  }

  async FindAllFinancialGroups() {
    return await this._httpService.get('/financial_group?orderByName=true');
  }

  async FindDependentsByBeneficiaryCpf(cpf) {
    return await this._httpService.get('/dependent/loadDependentsPhysicalPersonAndInvitationByBeneficiaryCpf/' + cpf);
  }

  async FindDependentContractedPlansPendingsByIds(ids) {
    return await this._httpService.get('/dependent_contracted_plan/ids?' + ids);
  }

 };
