/* eslint-disable */
import HttpService from '../HttpService';
export default class AccountService {
  constructor() {
    this._httpService = new HttpService('auth');
  }

  async GetTokenFull() {
    return await this._httpService.get('/token/full');
  }
}
