/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      //vazio
    },
    registered(registration) {

      // Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        registration.update();
      }, 1000 * 60 * 5); // time for checks
    },
    updated (registration) {

      // Add a custom event and dispatch it.
      // Used to display of a 'refresh' banner following a service worker update.
      // Set the event payload to the service worker registration object.
      document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: registration })
      );

    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}