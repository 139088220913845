<template>
  <img
    @click="pushRoute()"
    class="arrow-position"
    src="/img/icons/common/back-arrow.svg"
    :style="styleCSS"
  />
</template>
<script>
export default {
  name: "index",
  props: {
    styleCSS: {
      type: Object,
      default: () => {},
    },
    route: {
      required: false,
      type: String,
      default: null
    },
    action: {
      type: Function,
      default: null
    }
  },
  methods: {
    clickBack () {
      this.$router.go(-1)
    },
    pushRoute(){
      if(this.route) {
        this.$router.push({ name: this.route })
      }
      else if(this.action) {
        this.action();
      }
      else {
        this.clickBack()
      }
    }
  },
}
</script>

<style scoped>
.arrow-position {
  width: 28px;
  height: 23px;
  position: absolute;
  left: 25px;
  top: 15px;
}
</style>
