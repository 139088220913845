<template>
  <v-container fluid style="background-color: white; height: 100%">
    <v-expansion-panels v-model="openPanel" v-show="!isLoading" accordion multiple>
      <v-expansion-panel class="pa-0">
        <v-expansion-panel-header style="font-size: 24px">
          Dados do Beneficiário

          <template v-slot:actions>
            <v-icon size="40">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container class="pa-0 mt-1" fluid>
            <v-row
                style="font-size: 14px; color: rgba(0,0,0,.6)"
                align="center"
                justify="space-around"
            >
              <v-col cols="12" class="tertiary" v-if="verifyBeneficiaryOpenedCall(beneficiaryData)">
                <v-icon color="white" small>fas fa-headset</v-icon> <span style="color: white; font-weight: bold;">Abriu o Atendimento</span>
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">CPF: </span>
                {{ $util.formatCPF(beneficiaryData.personCPF) }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Nome: </span>
                {{ beneficiaryData.personName }}
              </v-col>
              <v-col cols="6" class="text-capitalize">
                <span class="font-weight-bold pr-2">Gênero: </span>
                {{ beneficiaryData.personGender }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Data de Nascimento: </span>
                {{ moment(beneficiaryData.personBirthDate).format('DD/MM/YYYY') }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Cartão: </span>
                {{ beneficiaryData.cardNumber }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Plano: </span>
                {{ beneficiaryData.planName }}
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="pa-0" v-if="dependents.length">
        <v-expansion-panel-header style="font-size: 24px">
          Dados dos Dependentes

          <template v-slot:actions>
            <v-icon size="40">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content v-for="(dependent, index) in dependents" :key="dependent.planId" class="pa-0 ma-0">
          <v-container class="pa-0 mt-1" fluid>
            <v-row
                style="font-size: 14px; color: rgba(0,0,0,.6);"
                align="center"
                justify="space-around"
            >
              <v-col cols="12" class="tertiary" v-if="verifyDependentOpenedCall(dependent)">
                <v-icon color="white" small>fas fa-headset</v-icon> <span style="color: white; font-weight: bold;">Abriu o Atendimento</span>
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">CPF: </span>
                {{ $util.formatCPF(dependent.personCPF) }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Nome: </span>
                {{ dependent.personName }}
              </v-col>
              <v-col cols="6" class="text-capitalize">
                <span class="font-weight-bold pr-2">Gênero: </span>
                {{ dependent.personGender }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Data de Nascimento: </span>
                {{ moment(dependent.personBirthDate).format('DD/MM/YYYY') }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Cartão: </span>
                {{ dependent.cardNumber }}
              </v-col>
              <v-col cols="6">
                <span class="font-weight-bold pr-2">Plano: </span>
                {{ dependent.planName }}
              </v-col>
            </v-row>
            <v-divider class="mt-4" v-if="index != (dependents.length - 1)"></v-divider>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { beneficiaryById } from '../../common/contract-service';
import { mapMutations } from 'vuex';
import moment from 'moment';
import ContractService from '../../services/contract/ContractService';
import XippAuthService from "@/services/auth/XippAuthService";

export default {
  name: 'beneficiaryDetail',
  data() {
    return {
      moment,
      beneficiaryId: null,
      token: null,
      email: null,
      beneficiaryData: {},
      isLoading: true,
      openPanel: [0, 1],
      dependents: [],
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    loadParams(){
      let { beneficiaryId, email, token } = this.$route.query;

      this.beneficiaryId = beneficiaryId;
      this.token = token;
      this.email = email;
      if(token){
        localStorage.setItem("LOCALIZED_TOKEN", token)
        localStorage.removeItem("SESSION");
        localStorage.removeItem("token");
      }
    },
    revalidateParams(){
      if(!this.token || !this.beneficiaryId){
        this.loadParams();
      }else{
        return;
      }

      if(!this.token || !this.beneficiaryId){
        this.$router.go();
      }
    },
    verifyBeneficiaryOpenedCall(beneficiary){
      return beneficiary && beneficiary.personEmail && this.email && (beneficiary.personEmail === this.email);
    },
    verifyDependentOpenedCall(dependent){
      return dependent && dependent.personEmail && this.email && (dependent.personEmail === this.email);
    },
    async getBeneficiary() {
      this.loading(true);
      this.isLoading = true;
      this.revalidateParams();
      await this.loadJWTToken();
      beneficiaryById(this.beneficiaryId, this.token).then(res => {
        this.beneficiaryData = res.data;
        this.loading(false);
      }).catch(err => {
        this.showmsg({ text: 'Erro ao carregar informações do beneficiário!', type: 'error' });
        this.loading(false);
        this.isLoading = false;
      })
    },
    async getDependents(){
      this.loading(true);
      this.isLoading = true;
      this.revalidateParams();
      await this.loadJWTToken();
      this._contractService = new ContractService();
      this._contractService.FindAllDependentAndContractPlanByBeneficiaryId(this.beneficiaryId)
          .then(response => {
            if(response.data){
              this.dependents = response.data;
              this.filterDependents();
              this.sortDependents();
              this.isLoading = false;
              this.loading(false);
            }
          }).catch(() => {
        this.showmsg({ text: 'Erro ao carregar informações dos dependentes.', type: 'error' });
        this.loading(false);
        this.isLoading = false;
      })
    },
    filterDependents(){
      if(this.dependents.length){
        this.dependents = this.dependents.filter(dependent => {
          return dependent.benefit != 'eu_protegido'
        })
      }
    },
    sortDependents(){
      if(this.dependents.length) {
        this.dependents.sort((a, b) => (a.personName > b.personName ? 1 : -1))
      }
    },
    async loadJWTToken() {
      if(this.token) {
        this._xippAuthService = new XippAuthService();
        await this._xippAuthService.GetTokenFull()
          .then((response) => {
              if(response && response.data) {
                  this.token = response.data.token;
                  localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
              }
          });
      }
    },
  },
  async created(){
    this.loadParams();
  },
  async mounted() {
    this.loadParams();
    await this.getBeneficiary();
    await this.getDependents();
  }
}
</script>

<style scoped>

</style>
