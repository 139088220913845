<template>
  <v-container style="overflow: auto !important;" fluid>
    <v-card>
      <v-card-text>
        <v-row dense>
          <v-col
            cols="12"
            md="6"
            v-if="target.feedItemId && target.feedItemId > 0"
          >
            <v-text-field
              :value="target.feedItemId"
              label="Feed"
              readonly
            />
          </v-col>
          <v-col cols="12" md="6" v-if="target.id && target.id > 0">
            <v-text-field :value="target.id" label="ID" readonly />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-select
              :disabled="!currentUser.salesforceUser"
              :items="typeList"
              v-model="target.type"
              label="Tipo"
              placeholder="Selecione o Tipo"
              outlined
            />
          </v-col>
          <v-col cols="12" md="6" >
            <v-radio-group row v-model="target.identifier" v-if="target.type === 'SEXO'">
              <v-radio value="MALE" label="Masculino"></v-radio>
              <v-radio value="FEMALE" label="Feminino"></v-radio>
              <v-radio value="ALL" label="Todos"></v-radio>
            </v-radio-group>
            <v-text-field
              v-else-if="target.type === 'IDADE_MAIOR_QUE' || target.type === 'IDADE_MENOR_QUE'"
              type="number"
              v-model="target.identifier"
              label="Identificador"
              outlined
              placeholder="Id externo do alvo quando necessário: Ex: Id do Contrato, Id do grupo Financeiro"
            />
            <v-text-field
              v-else
              type="text"
              v-model="target.identifier"
              label="Identificador"
              outlined
              placeholder="Id externo do alvo quando necessário: Ex: Id do Contrato, Id do grupo Financeiro"
            />
          </v-col>
        </v-row>


      <v-row>
        <v-col cols="6">
          <v-btn
            large
            color="secondary"
            light
            @click="cancelar()"
            class="black--text"
          >Voltar</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            large
            block
            color="primary"
            :loading="busy"
            :disabled="busy"
            @click="validatePost()"
          >Salvar
          </v-btn
          >
        </v-col>
      </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters, mapState } from "vuex";
import SocialNetworkService from "../../../services/socialnetwork/SocialNetworkService";

export default {
  name: "target-public-inserir",
  data: () => ({
    target: {
      type: null,
      id: 0,
      identifier: null,
      feedItemId: null,
    },
    currentUser: null,
    busy: false,
    typeList: [],
  }),
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    validatePost() {
      let isValid = true;

      if (this.target.type == null || this.target.type == "") {
        isValid = false;
        this.showmsg({
          text: "Necessário informar o Tipo!",
          type: "error",
        });
      } else if (!this.target.feedItemId || this.target.feedItemId < 0) {
        isValid = false;
        this.showmsg({
          text: "Erro na página. Volte para tela anterior!",
          type: "error",
        });
      }

      if (isValid) {
        this.savePost();
      }
    },
    savePost() {
      this.loading(true);
      let post = {
        feedItemId: this.target.feedItemId,
        identifier: this.target.identifier,
        type: this.target.type,
        id: this.target.id,
      };

      if (this.target.id > 0) {
        this._socialNetworkService
          .UpdateTarget(post)
          .then((_res) => {
            if (_res && _res.data && _res.data.id > 0) {
              this.showmsg({
                text: "Alterado com sucesso!",
                type: "success",
              });
            } else {
              this.showmsg({
                text: "Erro ao salvar o público alvo!",
                type: "error",
              });
            }
          })
          .catch(() =>
            this.showmsg({
              text: "Erro ao salvar o público alvo!",
              type: "error",
            })
          )
          .finally(() => {
            this.loading(false);
          });
      } else {
        this._socialNetworkService
          .SaveTarget(post)
          .then((_res) => {
            if (_res && _res.data && _res.data.id > 0) {
              this.target.id = _res.data.id;
              this.showmsg({
                text: "Incluído com sucesso!",
                type: "success",
              });
              this.cancelar()
            } else {
              this.showmsg({
                text: "Erro ao incluir o público alvo!",
                type: "error",
              });
            }
          })
          .catch(() =>
            this.showmsg({
              text: "Erro ao incluir o público alvo!",
              type: "error",
            })
          )
          .finally(() => {
            this.loading(false);
          });
      }
    },
    cancelar() {
      this.$router.push(`/publicacao/${this.target.feedItemId}`);
    },
    init() {
      this.currentUser = this.$auth.user();
      if (this.$route.query.id || this.$route.params.id) {
        this.target.id = this.$route.query.id
          ? this.$route.query.id
          : this.$route.params.id;

        if (this.target.id > 0) {
          this.loading(true);

          this._socialNetworkService
            .getTarget(this.target.id)
            .then((_res) => {
              if (_res && _res.data && _res.data.id) {
                this.target.type = _res.data.type;
                this.target.identifier = _res.data.identifier;
                this.target.feedItemId = _res.data.feedItemId;
              }
            })
            .catch(() =>
              this.showmsg({
                text: "Erro ao carregar o público alvo!",
                type: "error",
              })
            )
            .finally(() => {
              this.loading(false);
            });
        }
      }

      if (this.$route.query.feedItemId || this.$route.params.feedItemId) {
        this.target.feedItemId = this.$route.query.feedItemId
          ? this.$route.query.feedItemId
          : this.$route.params.feedItemId;
      }
    },
    getTypeList() {
      this.typeList = [];
      this.loading(true);
      this._socialNetworkService
        .getTargetTypeList()
        .then((_res) => {
          if (_res && _res.data && _res.data.length > 0) {
            _res.data.forEach((element) => {
              this.typeList.push({
                text: element.label,
                value: element.value,
              });
            });
          }
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar o lista de tipos!",
            type: "error",
          })
        )
        .finally(() => {
          this.loading(false);
        });
    },
  },
  async created() {
    this._socialNetworkService = new SocialNetworkService();
    this.init();
    this.getTypeList();
  },
};
</script>