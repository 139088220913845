<template>
  <v-container style="height: 100vh" class="bg-glass-effect pa-10">
    <v-layout style="height: 85vh" align-center justify-center>
      <v-row align="center" justify="center">
        <v-col class="pl-9" cols="4">
          <v-img
            style="max-height: 70px; max-width: 70px;z-index: 30"
            src="/img/app-logo-70.svg"
            alt="Eu Protegido"
          />
        </v-col>
        <v-col class="pl-0" cols="8">
          <p class="main-title ma-0">Eu Protegido</p>
        </v-col>

        <v-col cols="12">
          <p class="main-text text-center">
            Para instalar o aplicativo clique no botão <b>INSTALAR</b> e depois em adicionar.
          </p>
        </v-col>

        <v-col cols="12">
          <generic-btn
              :btn-props="{ block: true, loading: installing }"
              :on-click="install"
              class="mt-6"
          >
            Instalar
          </generic-btn>
        </v-col>
      </v-row>
    </v-layout>


    <v-dialog v-model="notInstalled" persistent>
      <v-card>
        <v-card-title primary-title dark class="primary white--text">Aplicativo não instalado</v-card-title>

        <v-card-text class="pt-6">
          Para instalar o aplicativo clique no botão <b>INSTALAR</b> e depois em adicionar.
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="notInstalled = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import $util from "../../common/util";
import browser from 'browser-detect';
import MobileDetect from '../../../src/plugins/mobile-detect.js'

export default {

  name: 'Install',
  data: () => ({
    installationEvent: null,
    notInstalled: false,
    installing: false,
    navegador: window.navigator.userAgent
  }),
  methods: {
    configureEvents() {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        this.installationEvent = event;
      });

      window.addEventListener('appinstalled', (event) => {
        this.installing = false;

        if (!$util.isMobile()) {
          setTimeout(() => this.$router.push('/'), 600);
        } else {
          this.window.location.href = this.window.location.href.split('/install')[1];
        }
      });

    },
    install() {
      if (!this.installationEvent) {
        window.location.reload();
      }

      this.installing = true;
      this.installationEvent.prompt();
      this.installationEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          return;
        }
        this.installing = false;
        this.notInstalled = true;
      });
    }
  },
  created() {
    const result = browser().name;
    const os = browser().os;
    let md = new MobileDetect(window.navigator.userAgent);

    if(md.is('iOS') === true){
      const browsersNotUsed = [md.is('Opera'), md.is('Edge'), md.is('Firefox'), md.is('Chrome')]
      for( let cont = 0; cont < browsersNotUsed.length ; cont++ ){
        if(browsersNotUsed[cont] === true) {
          this.$router.push({name: 'not-install'});
        }
      }
    }
    if(md.is('AndroidOS') === true){
      const browsersNotUsed = [md.is('Opera'), md.is('Edge'), md.is('Firefox'), md.is('Safari')]
      for( let cont = 0; cont < browsersNotUsed.length ; cont++ ){
        if(browsersNotUsed[cont] === true) {
          this.$router.push({name: 'not-install'});
        }
      }
    }

    if(result != 'chrome' && result != 'safari'){
      this.$router.push({name:'not-install',params:{browser:result,os:os}});
    }
    const isInstalled = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    if (isInstalled) {
      this.$router.push('/login');
      return;
    }
    this.configureEvents();
  }
};
</script>
