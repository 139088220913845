<template>
  <v-container style="overflow: auto !important;" fluid>
    <v-card class="mb-3">
      <v-card-text>
        <p class="display-1 text--primary">
          {{ timeline.id > 0 ? ('#' + timeline.id) : '[Novo]' }}
          {{ timeline.internalDescription && timeline.internalDescription.substring(0, 40) + '...' }} <small
            class="float-right"><i>{{ feedItemStatusLabel(timeline.feedItemStatus) }} </i></small>
        </p>
        <small>Criado em: {{ timeline.creationDate | formatDate }}</small>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-btn
                small
                color="secondary"
                light
                @click="cancelar()"
                class="black--text"
            >{{ !isNewRecord ? "Cancelar" : "Voltar" }}
            </v-btn
            >
          </v-col>
          <v-col cols="9" align="right">
            <v-btn
                small
                color="outline-primary"
                :disabled="buzy"
                :loading="buzy"
                @click="salvarRascunho()"
            > Salvar como Rascunho
            </v-btn>
            <v-btn
                v-if="this.timeline.feedItemStatus !== 'ARCHIVED' && !isNewRecord"
                small
                color="dark"
                class="ml-2"
                :disabled="buzy"
                :loading="buzy"
                @click="salvarArquivar()"
            > Arquivar
            </v-btn>
            <v-btn
                v-if="!isNewRecord"
                small
                class="ml-2"
                color="primary"
                :disabled="buzy"
                :loading="buzy"
                @click="salvarPublicar()"
            > Publicar
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <p>Qual o tipo de Publicação</p>
            <v-radio-group row v-model="timeline.feedItemType">
              <v-radio value="POST" label="Post no Feed"></v-radio>
              <v-radio value="TIP" label="Dica"></v-radio>
              <v-radio value="BANNER" label="Banner"></v-radio>
              <v-radio value="MESSAGE" label="Mensagem"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="6" v-if="sfUser">
            <p>Contexto da Publicação</p>
            <v-radio-group row v-model="timeline.applicationFilter">
              <v-radio value="MUNDO_A_DENTRO" label="Mundo a dentro"></v-radio>
              <v-radio value="MUNDO_A_FORA" label="Mundo a fora"></v-radio>
              <v-radio value="TODO_MUNDO" label="Todo mundo"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="4">
            <v-select
                :items="entityList"
                v-model="timeline.entity"
                v-if="timeline.feedItemType !== 'TIP' && timeline.feedItemType !== 'BANNER'"
                label="Entidade"
                placeholder="Selecione a entidade"
                outlined
            />
            <v-text-field
                type="text"
                v-if="timeline.feedItemType == 'TIP'"
                v-model="timeline.category"
                label="Categoria"
                outlined
                placeholder="Categoria da Dica"
            />
            <v-text-field
                type="number"
                v-if="timeline.feedItemType == 'TIP'"
                v-model="timeline.feedOrder"
                label="Ordem"
                outlined
                placeholder="Ordem"
            />
          </v-col>
          <v-col cols="12" md="4" align="center">
            <p>Data de Publicação </p>
            <v-date-picker
                v-model="timeline.publishDate"
            ></v-date-picker>
          </v-col>
          <v-col cols="12" md="4" align="center">
            <p>Data de Expiração
              <v-btn
                  x-small
                  @click="timeline.expirationDate = null"
              >Prazo indeterminado
              </v-btn
              >
            </p>
            <v-date-picker
                v-model="timeline.expirationDate"
            ></v-date-picker>
          </v-col>
          <v-col cols="12">
            <v-textarea
                v-model="timeline.description"
                label="Descrição (Publicada)"
                placeholder="Descrição que será publicada"
                auto-grow
                outlined
                hide-details
                class="pb-5"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
                v-model="timeline.internalDescription"
                label="Descrição (Interna)"
                placeholder="Descrição interna"
                auto-grow
                outlined
                hide-details
                class="pb-5"
            />
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title class="primary--text">
        {{this.loadingMedia ? 'Carregando Medias...' : 'Medias'}}
        <v-spacer/>
        <v-btn
            fab
            top
            right
            color="primary"
            dark
            @click="addMedia()"
        >
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>

          <v-col class="d-flex" cols="12" md="4" sm="6" lg="4" v-for="(media, index) in timeline.medias"
                 :key="'media'+media.id+media.keyGen">
            <v-card elevation="10" class="flex">
              <v-card-title class="primary--text">{{ media.type || '[Nova]' }}
                <v-spacer/>
                <v-btn fab top right small
                       color="danger"
                       dark
                       @click="removeMedia(index)"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </v-card-title>
              <v-col cols="12">
                <v-text-field
                    type="text"
                    v-model="media.url"
                    :disabled="(media.id != null && media.id > 0)"
                    label="Url da Media"
                    outlined
                    placeholder="Url da Media"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    type="number"
                    v-model="media.mediaOrder"
                    label="Ordem de Apresentação"
                    outlined
                    placeholder="Ordem de Apresentação"
                />
              </v-col>
              <v-col cols="12" v-if="!media.id && !media.image && !media.video">
                <v-file-input
                    v-model="media.file"
                    @change="setAttachments(media)"
                    truncate-length="15"
                    accept="video/*, image/*"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <iframe
                    :key="index + media.url"
                    v-if="media.url"
                    :src="media.url"
                    class="timeline-midia" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen></iframe>
              </v-col>
              <v-col cols="12" v-if="media.image || media.video">
                <v-img
                    width="100%"
                    v-if="media.image"
                    contain
                    :src="media.image"

                />
                <video v-if="media.video" width="100%" height="100%" controls>
                  <source :src="media.video" :type="media.videoType"/>
                  Your browser does not support the video tag.
                </video>
              </v-col>
              <v-col cols="12" v-if="!media.id && (media.image || media.video)">
                <div>
                  <v-card-actions>
                    <v-btn
                        block
                        large
                        color="secondary"
                        light
                        @click="clearMedia(media)"
                        class="black--text"
                    >Limpar Arquivo
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-col>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    <targetPublicListar v-if="!isNewRecord" :feed="{ id: timeline.id }" class="mb-3"/>
    <v-flex xs12 v-if="!isNewRecord && timeline.feedItemType == 'TIP'">
      <ProgressiveLoader :disabled="disableScroll" @reachedEnd="getTimeline">
        <v-card>
          <v-card-title class="primary--text">
            Publicações Relacionadas (dicas)
            <v-spacer/>
            <v-btn
                fab
                top
                right
                color="primary"
                dark
                @click="newSubTip()"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </v-card-title>

          <v-data-table
              id="timeline-listar"
              loading-text="Carregando publicações..."
              disable-filtering
              disable-pagination
              disable-sort
              hide-default-footer
              :headers="headers"
              :items="items"
              :loading="buzy"
              item-key="id"
              @click:row="(item) => handleItemSelection(item)"
          >
            <template v-slot:header></template>
            <template v-slot:no-data>
              <v-alert :value="true" type="info" v-show="!buzy" outlined>Nenhum item foi encontrado</v-alert>
            </template>
          </v-data-table>
        </v-card>
      </ProgressiveLoader>
    </v-flex>
  </v-container>
</template>

<script>
/* eslint-disable */
import {mapMutations, mapGetters, mapState} from "vuex";
import FormFileUpload from "@/components/generic-upload/FormFileUpload";
import SocialNetworkService from "../../../services/socialnetwork/SocialNetworkService";
import targetPublicListar from '../target-public/target-public-listar'
import ProgressiveLoader from "@/components/progressive-loader";
import FileUpload from 'vue-upload-component'

export default {
  components: {FileUpload, targetPublicListar, ProgressiveLoader},
  data() {
    return {
      sfUser: false,
      loadingMedia: false,
      timeline: {
        description: "",
        feedOrder: null,
        internalDescription: "",
        feedItemType: "DRAFT",
        feedItemStatus: "POST",
        applicationFilter: "MUNDO_A_DENTRO",
        id: 0,
        attachments: null,
        creationDate: '',
        expirationDate: '',
        publishDate: '',
        entity: 0,
        idMedia: 0,
        mediaUrl: '',
        medias: [],
        parent: this.$route.query.parentId && {id: parseInt(this.$route.query.parentId)}
      },
      postTypeList: [
        {text: "Post no Feed", value: "POST"},
        {text: "Banner ao Entrar no App", value: "BANNER"},
        {text: "Mensagem Direcionada", value: "MESSAGE"},
        {text: "Dicas do Nick", value: "TIP"},
      ],
      items: [],
      totalPages: 1,
      page: 0,
      buzy: false,
      disableScroll: false,
      image: null,
      video: null,
      videoType: null,
      entityList: [],
      medias: [],
    }
  },
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user"]),
    isNewRecord() {
      return this.timeline.id == null || this.timeline.id == 0
    },
    headers() {
      const baseHeaders = [
        {text: "#", value: "id", cols: "6"},
        {text: "Ordem", value: "feedOrder", cols: "6"},
        {text: "Entity", value: "entity", cols: "6"},
        {text: "Tipo", value: "feedItemType", cols: "6"},
        {text: "Status", value: "feedItemStatus", cols: "6"},
        {text: "Criado Em", value: "dataCriacao", cols: "12"},
        {text: "Expira Em", value: "dataExpiracao", cols: "12"},
        {text: "Publicação", value: "dataPublicacao", cols: "12"},
      ];
      if (this.$resize && this.$mq.above(this.window.mobileSize)) {
        baseHeaders.unshift({text: "", value: "", sortable: false, cols: ""});
      }
      return baseHeaders;
    },
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    handleItemSelection(item) {
      this.$router.push(`/publicacao/${item.id}?parentId=${this.$route.params.id}`);
    },

    async newSubTip() {
      this.$router.push(`/publicacao/0?parentId=${this.$route.params.id}`).then(() => window.scrollTo(0, 0))
    },

    async removeMedia(index) {
      this.timeline.medias.splice(index, 1);
    },

    async addMedia() {
      if (this.timeline.medias && this.timeline.medias.length > 2) {
        this.showmsg({
          text: "Só é permitido 3 medias por publicação.",
          type: "error",
        });
      } else {
        this.timeline.medias.push({id: 0, mediaOrder: 3, type: '', attachments: []})
      }

    },
    readFileContent(file) {
      const fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(new DOMException("Unable to parse the file."));
        };

        fileReader.onload = () => resolve({
          name: file.name,
          body: fileReader.result,
          size: file.size,
        });

        fileReader.readAsDataURL(file);
      });
    },
    async setAttachments(media) {
      if (media.file) {

        this.readFileContent(media.file)
            .then(file => {
              let data = file.body.split(";")[0];
              let type = data.split(":")[1];

              let f = {
                type: type,
                data: file.body.split(",")[1],
              };
              media.type = type;
              media.attachments = f;
              if (f.type.includes("image")) {
                media.image = file.body;
              } else {
                media.video = file.body;
                media.videoType = f.type;
              }
            })
      }
    },
    salvarRascunho() {
      this.timeline.feedItemStatus = 'DRAFT'
      this.validatePost()
    },
    salvarPublicar() {
      this.timeline.feedItemStatus = 'PUBLISHED'
      this.validatePost()
    },
    salvarArquivar() {
      this.timeline.feedItemStatus = 'ARCHIVED'
      this.validatePost()
    },
    validatePost() {
      if ((this.timeline.entity == null || this.timeline.entity <= 0)
          && this.timeline.feedItemType !== 'BANNER'
          && this.timeline.feedItemType !== 'TIP') {
        this.showmsg({
          text: "Necessário informar a Entidade!",
          type: "error",
        });
        return false
      }
      if (this.timeline.description == null ||
          this.timeline.description.length <= 0) {
        this.showmsg({
          text: "Necessário informar a Descrição!",
          type: "error",
        });
        return false

      }

      if (!this.timeline.feedItemType) {
        this.showmsg({
          text: "Necessário informar o Tipo",
          type: "error",
        });
        return false
      }

      if (this.timeline.medias && this.timeline.medias.length > 0) {
        for (var mediaIndex in this.timeline.medias) {
          let media = this.timeline.medias[mediaIndex]
          if (!media.mediaOrder) {
            this.showmsg({
              text: "Informar a ordem de apresentação da media.",
              type: "error",
            });
            return false
          }
          if (media.attachments) {
            if (!media.attachments.type.includes("image") && !media.attachments.type.includes("video")) {
              this.showmsg({
                text: "Tipos aceitos: Imagem ou Vídeo!",
                type: "error",
              });
              return false
            }
            if (!media.url && !media.video && !media.image) {
              this.showmsg({
                text: "Necessário informar o arquivo!",
                type: "error",
              });
              return false;
            }
          }
        }
      }

      this.savePost();
    },
    formatDate(data) {
      if (!data) return ''
      let datehoracompleta = data.split("T");
      let datehora = datehoracompleta[0].split("-");
      if (datehora.length == 3) {
        return `${datehora[2]}/${datehora[1]}/${datehora[0]}`;
      }
    },
    feedItemTypeLabel(enumVal) {
      if (enumVal === 'POST') {
        return 'Post'
      } else if (enumVal === 'TIP') {
        return 'Dica'
      } else if (enumVal === 'MESSAGE') {
        return 'Mensagem'
      } else if (enumVal === 'BANNER') {
        return 'Banner'
      }
      return ''
    },
    feedItemStatusLabel(enumVal) {
      if (enumVal === 'PUBLISHED') {
        return 'Publicado'
      } else if (enumVal === 'DRAFT') {
        return 'Rascunho'
      } else if (enumVal === 'ARCHIVED') {
        return 'Arquivado'
      }
      return ''
    },
    async getTimeline() {
      let comp = this;
      let items = this.items;
      let getUser = this.$auth.user();
      this.disableScroll = true;
      if (this.timeline.id && this.timeline.feedItemType == 'TIP') {
        var _res = await this._socialNetworkService
            .findFeedItemUserAdmin(getUser.id, this.timeline.id, comp.page);

        if (_res.data && _res.data.content.length > 0) {
          this.disableScroll = false;
          _res.data.content.forEach(function (elem) {
            let store = {
              id: elem.id,
              entity: elem.entity_name,
              feedOrder: elem.feed_order,
              entityId: elem.entity_id,
              contents: elem.contents,
              applicationFilter: elem.application_filter,
              dataCriacao: comp.formatDate(elem.creation_date),
              dataExpiracao: comp.formatDate(elem.expiration_date),
              dataPublicacao: comp.formatDate(elem.publish_date),
              feedItemType: comp.feedItemTypeLabel(elem.feed_item_type),
              feedItemStatus: comp.feedItemStatusLabel(elem.feed_item_status),
              contentsSize:
                  elem.contents && elem.contents.length > 150
                      ? elem.contents.substring(0, 149)
                      : elem.contents,
            };

            items.push(store);
            comp.page++;
          });
        } else {
          comp.disableScroll = true;
        }
      }
    },
    savePost() {
      let getUser = this.$auth.user();
      this.buzy = true;
      let post = {
        feed: {
          contents: this.timeline.description,
          category: this.timeline.category,
          feedOrder: this.timeline.feedOrder,
          internalDescription: this.timeline.internalDescription,
          expirationDate: this.timeline.expirationDate,
          publishDate: this.timeline.publishDate,
          feedItemType: this.timeline.feedItemType,
          feedItemStatus: this.timeline.feedItemStatus,
          applicationFilter: this.timeline.applicationFilter,
          id: this.timeline.id,
          entityId: this.timeline.entity,
          parent: this.timeline.parent,
        },
        medias: this.timeline.medias,
        mediaOld: this.medias,
      };

      //Se existir, é pq foi alterado.
      if (post.medias && post.medias.length > 0) {
        post.medias.forEach((media) => {
          if (media.attachments && media.attachments.type) {
            media.type = media.attachments.type;
            media.fileContent = media.attachments.data;
            media.id = 0;
          }
        })
      }

      let texto =
          this.timeline.id > 0
              ? "Post alterado com sucesso"
              : "Post incluído com sucesso";

      this._socialNetworkService
          .SavePost(post)
          .then((_res) => {
            if (_res && _res.data && _res.data.id > 0) {
              this.showmsg({
                text: texto,
                type: "success",
              });
              this.$router.push(`/publicacoes`);
            } else {
              this.showmsg({
                text: "Erro ao salvar a publicação!",
                type: "error",
              });
            }
          })
          .catch((ex) =>
              this.showmsg({
                text: "Erro ao salvar a publicação! " + ex.response.data.message,
                type: "error",
              })
          )
          .finally(() => {
            this.buzy = false;
          });
    },
    cancelar() {
      this.$router.push(`/publicacoes`);
    },
    clearMedia(media) {
      media.image = null;
      media.attachments = [];
      media.file = null;
      media.video = null;
      media.type = null;
      media.videoType = null;
      media.mediaOrder = 3;
      media.id = 0;
    },
    init() {
      let comp = this;
      this.sfUser = localStorage.getItem('LOCALIZED_ORIGIN') === 'salesforce'
      if (comp.$route.query.id || comp.$route.params.id) {
        comp.buzy = true;
        comp.timeline.id = comp.$route.query.id || comp.$route.params.id;

        if (comp.timeline.id > 0) {

          this._socialNetworkService
              .getFeedId(comp.timeline.id)
              .then((_res) => {
                if (_res && _res.data && _res.data.id) {
                  comp.timeline.description = _res.data.contents;
                  comp.timeline.internalDescription = _res.data.internalDescription;
                  comp.timeline.applicationFilter = _res.data.applicationFilter;
                  comp.timeline.entity = _res.data.entityId;
                  comp.timeline.feedOrder = _res.data.feedOrder;
                  comp.timeline.expirationDate = _res.data.expirationDate;
                  comp.timeline.publishDate = _res.data.publishDate;
                  comp.timeline.creationDate = _res.data.creationDate;
                  comp.timeline.entity = _res.data.entityId;
                  comp.timeline.category = _res.data.category;
                  comp.timeline.feedItemType = _res.data.feedItemType;
                  comp.timeline.feedItemStatus = _res.data.feedItemStatus;
                  comp.timeline.parent = _res.data.parent;
                }
              })
              .catch(() =>
                  this.showmsg({
                    text: "Erro ao carregar a publicação!",
                    type: "error",
                  })
              )
              .finally(() => {
                this.buzy = false;
                this.getMidia();
              });
        } else {
          this.buzy = false;
        }
      }
    },
    getMidia() {
      let comp = this;
      this.loadingMedia = true;
      this._socialNetworkService
          .GetMidiaFeed(this.timeline.id)
          .then((_res) => {
            if (_res.data && _res.data.length > 0) {

              _res.data.forEach((media) => {
                media.mediaOrder = media.media_order;
                let midiaStream = `data:${media.type};base64, ${media.contents}`;
                if (media.type.includes("image")) {
                  media.image = midiaStream;
                } else if (media.type.includes("video/mp4")) {
                  media.video = midiaStream;
                  media.videoType = media.type;
                }
              })

              _res.data.forEach((element) => {
                comp.timeline.medias.push(element);
                this.medias.push({
                  idFeedMedia: element.id_feed, //id_feed = feedMediaId
                  idMedia: element.id,
                });
              });
            }
          })
          .catch((ex) => {
          })
          .finally(() => {
            this.loadingMedia = false;
          });
    },
    getEntityList() {
      this.entityList = [];
      let getUser = this.$auth.user();
      this._socialNetworkService
          .getEntityListPerUser(getUser.id)
          .then((_res) => {
            if (_res && _res.data && _res.data.length > 0) {
              _res.data.forEach((element) => {
                this.entityList.push({
                  text: element.entity_name,
                  value: element.entity_id,
                });
              });
            }
          })
          .catch(() =>
              this.showmsg({
                text: "Erro ao carregar as entidades!",
                type: "error",
              })
          )
          .finally(() => {
          });
    },
    target() {
      this.$router.push(`/target-public/${this.timeline.id}`);
    },
  },
  created() {
    this._socialNetworkService = new SocialNetworkService();
    this.init();
    this.getEntityList();
  },
};
</script>


<style>
.timeline-midia {
  width: 100%;
}
</style>
