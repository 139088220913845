<template>
  <GenericDrawer right :open="$store.state.drawerOpen">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{this.title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeSideDrawer">
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>

    <GenericFilterer
            ref="genericFilterer"
            :filters="filters"
            :data="data"
            :baseQuery="baseQuery"
            @queryUpdate="_ => $emit('queryUpdate', _)"
            @filterChange="emitData"
            @filtersRemoved="(eventValue) => $emit('filtersRemoved', eventValue)"/>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block large class="primary" @click="closeSideDrawer">Aplicar</v-btn>
      </div>
      <div class="pa-2">
        <v-btn block large class="secondary text--primary" @click="clearFilters">Limpar</v-btn>
      </div>
    </template>
  </GenericDrawer>
</template>

<script>
import GenericDrawer from "@/components/generic-drawer";
import GenericFilterer from "@/components/generic-filterer";

export default {
  name: "DrawerFilterer",
  components: { GenericDrawer, GenericFilterer },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    baseQuery: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Filtrar Resultados'
    },
  },
  methods: {
    emitData(dataToEmit) {
      this.$emit('filterChange', dataToEmit);
    },
    closeSideDrawer() {
      this.$store.commit('setFilterDrawer', false);
      this.$store.commit('setAbledToSearch', true);
    },
    clearFilters() {
      this.$store.commit('setFilterDrawer', false);
      this.$refs.genericFilterer.clearFilters();
      this.$emit('clearFilters');
    }
  },
};

</script>
