<template>
  <v-container style="height: 100%" class="px-3 pt-16 bg-effect">
    <Totalizer class="mb-2" :totals="totals" :title="title" color="#532E88" />
    <v-row
      ><v-col>
        <v-layout justify-center align-center>
          <v-card
            style="background: rgba(231, 234, 254, 0.6)"
            class="option-list-card list-card mb-6"
          >
            <v-row justify="center">
              <v-col class="pl-6 pr-0 pt-5" cols="7">
                <div
                  :class="!activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
                  :style="!activeFilter && 'background: white; height: 50px'"
                >
                  <p
                    style="
                      font-size: 16px !important;
                      font-weight: bolder !important;
                    "
                    class="text-center generic-text mb-0"
                    @click="() => (activeFilter = !activeFilter)"
                  >
                    Conectados
                  </p>
                </div>
              </v-col>
              <v-col class="pl-0 pr-7 pt-5 mt-0" cols="5">
                <div
                  :class="activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
                  :style="activeFilter && 'background: white; height: 50px'"
                >
                  <p
                    style="
                      font-size: 16px !important;
                      font-weight: bolder !important;
                      height: 50% !important;
                    "
                    class="text-center generic-text mb-0"
                    @click="() => (activeFilter = !activeFilter)"
                  >
                    Não Conectados
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-layout>
        <v-row class="mb-3 mx-0 justify-end">
          <v-dialog v-model="filterDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed color="primary" v-bind="attrs" v-on="on">
                Filtrar
                <v-icon right>fas fa-filter</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 primary lighten-4 white--text">
                Filtrar por:
              </v-card-title>

              <v-card-text>
                <v-autocomplete
                  class="mt-4"
                  v-model="filter.financialGroup"
                  item-text="name"
                  item-value="id"
                  :items="financialGroups"
                  label="Grupo Financeiro"
                  placeholder="Selecione o grupo financeiro"
                ></v-autocomplete>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="clearFilters()">
                  Limpar
                </v-btn>
                <v-btn color="primary" text @click="filterUsers()">
                  Filtrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-layout align-center justify-center>
          <v-row v-if="!activeFilter" align="center" justify="center">
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="connectedUsers"
                :items-per-page="10"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:no-data>
                  Nenhum usuário conectado encontrado
                </template>
                <template v-slot:[`item.financialGroupName`]="{ item }">
                  {{ item.financialGroupName ? item.financialGroupName : "-" }}
                </template>
                <template v-slot:[`item.policy`]="{ item }">
                  {{ item.policy ? item.policy : "-" }}
                </template>
                <template v-slot:[`item.subcontractName`]="{ item }">
                  {{ item.subcontractName ? item.subcontractName : "-" }}
                </template>
                <template v-slot:[`item.registration`]="{ item }">
                  {{ item.registration ? item.registration : "-" }}
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  {{ item.name ? item.name : "-" }}
                </template>
                <template v-slot:[`item.holder`]="{ item }">
                  {{ item.holder ? item.holder : "-" }}
                </template>
                <template v-slot:[`item.attachments`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        @click="details(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Detalhar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-pagination
                v-model="params.page"
                class="my-4"
                :length="params.totalPages"
                total-visible="10"
                @input="loadUsers()"
              ></v-pagination>
            </v-col>
          </v-row>
          <v-row v-if="activeFilter" align="center" justify="center">
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="nonConnectedUsers"
                :items-per-page="10"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:no-data>
                  Nenhum usuário desconectado encontrado
                </template>
                <template v-slot:[`item.financialGroupName`]="{ item }">
                  {{ item.financialGroupName ? item.financialGroupName : "-" }}
                </template>
                <template v-slot:[`item.policy`]="{ item }">
                  {{ item.policy ? item.policy : "-" }}
                </template>
                <template v-slot:[`item.subcontractName`]="{ item }">
                  {{ item.subcontractName ? item.subcontractName : "-" }}
                </template>
                <template v-slot:[`item.registration`]="{ item }">
                  {{ item.registration ? item.registration : "-" }}
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  {{ item.name ? item.name : "-" }}
                </template>
                <template v-slot:[`item.holder`]="{ item }">
                  {{ item.holder ? item.holder : "-" }}
                </template>
                <template v-slot:[`item.attachments`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        @click="details(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Detalhar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-pagination
                v-model="params.page"
                class="my-4"
                :length="params.totalPages"
                total-visible="10"
                @input="loadUsers()"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Totalizer from "@/components/generic-totalizer/index";
import ContractService from "@/services/contract/ContractService";
import { mapMutations } from "vuex";
import XippAuthService from "@/services/auth/XippAuthService";

export default {
  name: "usersView",
  components: {
    Totalizer,
  },
  data: () => ({
    totals: [
      {
        id: "totalActive",
        label: "Usuários Conectados",
        value: 0,
      },
      {
        id: "totalInactive",
        label: "Usuários Não Conectados",
        value: 0,
      },
    ],
    headers: [
      {
        text: "Grupo Financeiro",
        align: "start",
        sortable: true,
        value: "financialGroupName",
      },
      {
        text: "Apólice",
        align: "center",
        sortable: true,
        value: "policy",
      },
      {
        text: "Subcontrato",
        align: "center",
        sortable: true,
        value: "subcontractName",
      },
      {
        text: "Matrícula",
        align: "center",
        sortable: true,
        value: "registration",
      },
      {
        text: "Nome",
        align: "center",
        sortable: true,
        value: "name",
      },
      {
        text: "Nome do Titular",
        align: "center",
        sortable: true,
        value: "holder",
      },
      {
        text: "Ações",
        align: "end",
        sortable: false,
        value: "attachments",
      },
    ],
    connectedUsers: [],
    nonConnectedUsers: [],
    financialGroups: [],
    filter: {
      financialGroup: null,
    },
    params: {
      page: 1,
      size: 10,
      totalPages: 1,
      totalElements: 0,
    },
    filterDialog: false,
    activeFilter: false,
    title: "Consulta de Usuários",
  }),
  watch: {
    activeFilter(val) {
      this.filter.financialGroup = null;
    },
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    loadTotalsActiveUsers() {
      this.totals[0].value = this.connectedUsers.length;
    },
    loadTotalsInactiveUsers() {
      this.totals[1].value = this.nonConnectedUsers.length;
    },
    loadUsers() {
      if (this.params.page > 0) {
        this.params.page -= 1;
      }
      this.loading(true);
      this._contractService
        .GenerateUserReportByStatus(
          this.params.page,
          this.params.size,
          this.filter.financialGroup
        )
        .then((response) => {
          this.connectedUsers = response.data.connectedUsers;
          this.nonConnectedUsers = response.data.waitingUsers;
          this.params.page = response.data.page + 1;
          this.params.size = response.data.size;
          this.params.totalPages = response.data.totalPages;
          this.params.totalElements = response.data.totalElements;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data.message ===
              "Não existem grupos financeiros atrelados aos beneficiários."
          ) {
            this.showmsg({
              text: "Não existem grupos financeiros atrelados aos beneficiários",
              type: "error",
            });
          } else if (
            error.response &&
            error.response.data.message ===
              "Não existem planos atrelados aos beneficiários."
          ) {
            this.showmsg({
              text: "Não existem planos atrelados aos beneficiários.",
              type: "error",
            });
          } else if (
            error.response &&
            error.response.data.message ===
              "Não existem contratos/subcontratos atrelados aos beneficiários."
          ) {
            this.showmsg({
              text: "Não existem contratos/subcontratos atrelados aos beneficiários.",
              type: "error",
            });
          } else {
            this.showmsg({
              text: "Não foi possível carregar os usuários.",
              type: "error",
            });
          }
        })
        .finally(() => {
          this.loading(false);
          this.loadTotalsActiveUsers();
          this.loadTotalsInactiveUsers();
        });
    },
    async loadFinancialGroups() {
      await this._contractService
        .FindAllFinancialGroups()
        .then((response) => {
          this.financialGroups = response.data;
        })
        .catch(() => {
          this.showmsg({
            text: "Não foi possível carregar os grupos financeiros.",
            type: "error",
          });
        });
    },
    details(item) {
      this.$router.push({ name: "user-detail", params: { user: item } });
    },
    filterUsers() {
      this.filterDialog = false;
      this.loadUsers();
    },
    clearFilters() {
      this.filterDialog = false;
      this.filter.financialGroup = null;
      this.loadUsers();
    },
    async loadJWTToken() {
      this._xippAuthService = new XippAuthService();
      await this._xippAuthService.GetTokenFull()
        .then((response) => {
            if(response && response.data) {
                localStorage.setItem("LOCALIZED_TOKEN", response.data.token)
            }
        }).finally(() => {
            this.loading(false);
        });
    },
  },
  async created() {
    const { token, origin } = this.$route.query;
    if (token) {
      localStorage.setItem("LOCALIZED_TOKEN", token);
      localStorage.setItem("LOCALIZED_ORIGIN", origin);
      localStorage.removeItem("SESSION");
      localStorage.removeItem("token");
      this.loading(true);
      await this.loadJWTToken();
      this.loading(false);
    }

    this._contractService = new ContractService();
    this.loading(true);
    this.loadFinancialGroups();
    this.loadUsers();
  },
};
</script>

<style scoped>
/*background effect blur*/
.list-card {
  border-radius: 20px !important;
}

.option-list-card {
  width: 100% !important;
  height: 65px !important;
}

.bg-effect {
  background: rgba(255, 255, 255, 0.55);
  mix-blend-mode: normal;
  padding: 10px;
}
</style>
