import { render, staticRenderFns } from "./appConfigurationFieldGroup.vue?vue&type=template&id=6232b05a&scoped=true&class=container-background&"
import script from "./appConfigurationFieldGroup.vue?vue&type=script&lang=js&"
export * from "./appConfigurationFieldGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6232b05a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCol,VContainer,VRow,VSubheader})
