
export default {
    state: {
        userId: 0,
        allMessages: [],
        messagesGroupingBySender: [],
        countUnreadMessages: 0,
        chatBots: [],
        chatBotMessages: [],
    },
    mutations: {
        setAllMessages(state, allMessages) {
            state.allMessages = allMessages;
        },
        setAllMessagesBySender(state, allMessages) {
            let messages = [];
            messages.push({
                senderId: allMessages.senderId,
                messages: allMessages.messages
            })

            state.allMessages = messages;
        },
        setMessagesGroupingBySender(state, messagesGroupingBySender) {
            state.messagesGroupingBySender = messagesGroupingBySender;
        },
        setCountUnreadMessages(state, countUnreadMessages) {
            state.countUnreadMessages = countUnreadMessages;
        },
        setChatBots(state, chatBots) {
            state.chatBots = chatBots;
        },
        setChatBotMessages(state, chatBotMessages) {
            state.chatBotMessages = chatBotMessages;
        },
        clearChatBotMessage(state) {
            state.chatBotMessages = []
        },
        addChatBotMessage(state, chatBotMessages) {
            state.chatBotMessages.push(chatBotMessages);
        },
        setConversationScroll(state) {
            this.container = document.getElementById ( "notification-body" )
            if(this.container)
                this.container.scrollTop = this.container.scrollHeight;
        },
        setUserIdAndResetAll(state, userId){
            if(state.userId !== userId) {
                state.chatBotMessages = [];
            }
            state.userId = userId;
        }
    },
    getters: {
        getUserId: (state) => state.userId,
        getAllMessages: (state) => state.allMessages,
        getMessagesGroupingBySender: (state) => state.messagesGroupingBySender,
        getCountUnreadMessages: (state) => state.countUnreadMessages,
        getChatBots: (state) => state.chatBots,
        getChatBotMessages: (state) => state.chatBotMessages
    },
}
