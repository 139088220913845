<template class="container-background">
  <v-container fluid style="background-color: white">
    <v-expansion-panels accordion multiple v-show="!isLoading">
      <v-expansion-panel >
        <v-expansion-panel-header>Aplicativo</v-expansion-panel-header>
        <v-expansion-panel-content>
          <config-group :entity-configurations="appConfigurations" :editing="editing" v-on:startEdit="startEdit" v-show="appConfigurations.length > 0"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Grupo Financeiro</v-expansion-panel-header>
        <v-expansion-panel-content>
          <config-group :entity-configurations="financialGroupConfigurations" :editing="editing" v-on:startEdit="startEdit" v-show="financialGroupConfigurations.length > 0"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-for="(contractID, i) in contractIds" :key="i">
        <v-expansion-panel-header>Contrato {{contractID}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <config-group :entity-configurations="contractConfigurationsMap.get(contractID)" :editing="editing" v-on:startEdit="startEdit"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row no-gutters class="text-center" v-show="editing">
      <v-col>
        <v-btn @click="cancelEdit" depressed class="mr-2">Cancelar</v-btn>
        <v-btn @click="save" depressed color="rgb(84,79,105)" style="color: white">Salvar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getAppAndRelatedFinancialGroupConfigurations, saveAppConfigurations } from '../../common/securityserver';
import {mapMutations} from "vuex";
import ConfigGroup from "@/view/config-app/appConfigurationFieldGroup";


export default {
  name: "configFinancialGroup",
  components: {ConfigGroup},
  data: () => ({
    token: "",
    appName: "",
    recordId: "",
    appConfigurations: [],
    financialGroupConfigurations: [],
    contractIds: [],
    contractConfigurationsMap: new Map(),
    editing: false,
    isLoading: true,
  }),
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    save(){
      this.loading(true);
      this.isLoading = true;
      const mixedConfigurations = this.mergeConfigurations();
      for(const appConfiguration of mixedConfigurations){
        if(appConfiguration.fieldType == 'COMBO'){
          appConfiguration.value = appConfiguration.value.join(",");
        }
      }
      saveAppConfigurations(mixedConfigurations, this.token)
          .then((response) =>{

            for(const appConfiguration of response.data){
              if(appConfiguration.fieldType == 'COMBO' || appConfiguration.fieldType == 'SELECT' ){
                appConfiguration.options = appConfiguration.optionValues ? appConfiguration.optionValues.split(",") : [];
              }
              if(appConfiguration.fieldType == 'COMBO'){
                appConfiguration.value = appConfiguration.value ? appConfiguration.value.split(",") : [];
              }
            }

            this.separateConfigurations(response.data);
            this.showmsg({ text: "As configurações foram salvas!", type: "success" });
          })
          .catch((error) => {
            this.showmsg({ text: "Erro ao salvar configurações!", type: "error" });
          })
          .finally(() => {
            this.loading(false);
            this.isLoading = false;
            this.editing = false

          })
    },
    cancelEdit(){
      this.loadConfigurations();
      this.editing = false
    },
    startEdit(){
      this.editing = true
    },
    loadConfigurations(){
      this.loading(true);
      this.isLoading = true;
      this.appConfigurations = [];
      this.financialGroupConfigurations = [];
      this.contractConfigurationsMap = new Map();
      this.contractIds = [];
      getAppAndRelatedFinancialGroupConfigurations(this.appName, this.recordId, this.token)
          .then((response) => {
            this.separateConfigurations(response.data);
          })
          .catch((error) => {
            this.showmsg({ text: "Erro ao carregar configurações!", type: "error" });
          }).finally(() => {
            this.loading(false);
            this.isLoading = false;
          })
    },
    separateConfigurations(mixedConfigurations){
      mixedConfigurations.forEach((configuration) => {
        let contractConfigurations;
        switch (configuration.entityType){
          case 'APP':
            this.appConfigurations.push(configuration);
            break;
          case 'FINANCIAL_GROUP':
            this.financialGroupConfigurations.push(configuration);
            break;
          case 'CONTRACT':
            contractConfigurations = this.contractConfigurationsMap.get(configuration.entityID);
            if(contractConfigurations){
              contractConfigurations = [...contractConfigurations, configuration]
            }else{
              contractConfigurations =  [configuration];
              this.contractIds.push(configuration.entityID)
            }
            this.contractConfigurationsMap.set(configuration.entityID, contractConfigurations);
            break;
        }
      })
    },
    mergeConfigurations(){
      let mixedConfigurations = [...this.appConfigurations, ...this.financialGroupConfigurations];
      for(let contractId of this.contractIds){
        mixedConfigurations.push(...this.contractConfigurationsMap.get(contractId));
      }
      return mixedConfigurations;
    }
  },
  created() {
    const {token, appName, recordId} = this.$route.query;

    this.token = token;
    this.appName = appName;
    this.recordId = recordId;


    this.loadConfigurations();

  },

}
</script>

<style scoped>

</style>