export default {
  state: {
    app: {
      mainView: true,
      dialogLoading: false,
      reloadableKey: new Date().getTime(),
      mainSearch: '',
      structure: {
        heightBottom: '',
        heightToolbar: '48px',
        heightMenuMobile: '60px',
        paddingBottom: ''
      },
      addProtecoes: false,
      loading: false,
    },
  },
  mutations: {
    refreshReloadableKey(state) {
      state.app.reloadableKey = new Date().getTime()
    },
    updateStructure(state, structure) {
      state.app.structure = Object.assign(state.app.structure, structure);
    },
    toogleDialogLoading(state, value) {
      state.app.dialogLoading = value
      this.commit('toogleMainView', !state.app.dialogLoading)
    },
    toogleMainView(state, value) {
      state.app.mainView = value
    },
    toogleAddProtecoes(state, value) {
      state.app.addProtecoes = value
    },
    updateMainSearch(state, value) {
      state.app.mainSearch = value
    },
    loading(state, bool) {
      state.app.loading = bool
    },
  },
  getters: {
    mainSearch: state => {
      return state.app.mainSearch ? state.app.mainSearch : ''
    },
    loading: (state) => state.loading,
  },
}
