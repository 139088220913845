/* eslint-disable */
import HttpService from '../HttpService';
export default class UserService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`security/user`);
  }

  async reloadSessionByToken(token) {
    return this._httpService.get('/reloadSessionByToken/'+ token);
  }

  async findUserEuProtegidoByCpf(cpf) {
    return this._httpService.get('/find_by_cpf_or_physical_person', { cpf });
  }

}
