import { getCurrentUser, getProfilePicture, loadMenu, logoutSession } from '@/common/securityserver';
import * as Sentry from "@sentry/vue";

var _vm = null;

const SESSION_BIOMETRIC_KEY = 'BIOMETRIC';
const SESSION_STORAGE_KEY = 'SESSION';
const LOCALIZED_TOKEN = 'LOCALIZED_TOKEN'
const TERM_FLAG = 'useTermsAccepted';

export default {
  userIsSF() {
    return (this.isAuthenticated() && this.user())
  },
  user() {
    let session = this.getSession();

    if (session && session.user)
      return session.user
    else
      return false;
  },
  getBeneficiariesAndDependents() {
    const allBeneficiaries = [];

    if(this.user() && this.user().beneficiaries) {
      const beneficiaries = this.user().beneficiaries;
      const personBeneficiary = {
        name: beneficiaries[0].personName ? beneficiaries[0].personName  + ' (Titular)' : null,
        userId: beneficiaries[0].userId,
        dependentId: null,
        cpf: beneficiaries[0].personCPF
      };

      allBeneficiaries.push(personBeneficiary);
      beneficiaries.forEach(beneficiary => {

        if(beneficiary.dependents && beneficiary.dependents.length) {
          beneficiary.dependents.forEach(dependent => {
            if(!allBeneficiaries.some(beneficiaryPerson => beneficiaryPerson.cpf === dependent.personCPF) && _vm.$util.isDependentGreaterThanAgeLimit(dependent.personBirthDate)) {
              const personDependent = {
                name: dependent.personName ? dependent.personName + ' (Dependente)' : null,
                userId: dependent.userId,
                dependentId: dependent.dependentId,
                cpf: dependent.personCPF
              };
              allBeneficiaries.push(personDependent);
            }
          });
        }
      });

      return allBeneficiaries;
    } else if (this.user() && this.user().dependents) {
      const dependents = this.user().dependents;
      const personDependent = {
        name: dependents[0].personName ? dependents[0].personName + ' (Dependente)' : null,
        userId: dependents[0].userId,
        dependentId: dependents[0].dependentId,
      };

      allBeneficiaries.push(personDependent);
      return allBeneficiaries;
    } else {
      return [];
    }
  },
  getBeneficiarySituation() {
    const user = this.user();
    let beneficiarySituation = null;
    if(user && user.beneficiaries && user.beneficiaries.length) {
      beneficiarySituation = user.beneficiaries[0].beneficiarySituation;
    } else if(user && user.dependents && user.dependents.length) {
      beneficiarySituation = user.dependents[0].beneficiarySituation;
    }

    if(!beneficiarySituation){
      return 'ATIVO';
    }
    return beneficiarySituation;
  },
  setUser(user) {
    Sentry.setUser(user);
    let session = this.getSession();
    Object.assign(session.user, user);
    this.setSession(session);
  },
  getSession() {
    if (localStorage.getItem(SESSION_STORAGE_KEY)) {
      return JSON.parse(localStorage.getItem(SESSION_STORAGE_KEY))
    } else return false
  },
  getLocalizedToken() {
    if (localStorage.getItem(LOCALIZED_TOKEN)) {
      return localStorage.getItem(LOCALIZED_TOKEN)
    } else return false
  },
  setSession(session) {
    if (session) {
      localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(session));
      _vm.$store.commit('user', session.user);
    }
  },
  getAccessToken() {
    return this.getSession().tokenSecurityService.access_token;
  },
  getMenuNick() {
    return this.getMenuByTitle('Nick');
  },
  getMenuOpcoes() {
    return this.getMenuByTitle('Opcoes');
  },
  getMenuByTitle(title){
    var menu = this.getSession().menu && this.getSession().menu.find(m => {
      return m.title === title
    });
    return menu && menu.subMenus;
  },
  getBeneficiaryCards(){
    return this.getSession().user.beneficiaries
  },
  getDependentesCards(){
    return this.getSession().user.dependents
  },
  isAuthenticated() {
    let isAuthenticated = false;
    const session = this.getSession();
    if (session) {
      const minutes = 10 // 10 minutes (session time limit)
      const seconds = 60
      const miliseconds = 1000
      const sessionTime = minutes * seconds * miliseconds

      const mstime = new Date().getTime() - session.createDate
      const restTime = new Date().getTime() - (session.activeDate + miliseconds)
      if (mstime <= sessionTime || restTime <= sessionTime) {
        isAuthenticated = true
      }
    }

    return isAuthenticated
  },
  resetActiveDate(isLoading = false, meta = null, path = null){
    if(!meta.skipInstall && (path && path !== '/install' && path !== '/login')) {
      if(isLoading && !this.isAuthenticated()) {
        this.clearSessionAndRedirectToLogin();
      }

      const session = this.getSession();
      if(session){
        session.activeDate = new Date().getTime();
        this.setSession(session);
      }
    }
  },
  askForAuthentication() {
    try {
      if (_vm.$route.matched.length <= 0)
        return false
      else if (_vm.$route.meta && typeof _vm.$route.meta.auth === "boolean")
        return _vm.$route.meta.auth;
    } catch (error) {
      // vazio
    }

    return true;
  },
  clearSessionAndRedirectToLogin() {
    localStorage.removeItem(SESSION_STORAGE_KEY);
    _vm.$util.link('login')
  },
  checkAuthentication() {
    if (this.askForAuthentication() && !this.isAuthenticated()) {
      this.clearSessionAndRedirectToLogin();
    }
  },
  createBiometricSession(biometricData) {
    sessionStorage.setItem(SESSION_BIOMETRIC_KEY, btoa(JSON.stringify(biometricData)));
  },
  async createSession(tokenSecurityService, options) {

    if (this.getSession())
    {
      localStorage.removeItem(SESSION_STORAGE_KEY);
      localStorage.removeItem('LOCALIZED_SESSION');
      localStorage.removeItem('LOCALIZED_ORIGIN');
    }

    const user = await getCurrentUser(tokenSecurityService);
    let profilePicture = options.profilePicture;
    const menu = await loadMenu(tokenSecurityService);


    await getProfilePicture(tokenSecurityService)
      .then((response) => {
        if (response.imgContent) {
          profilePicture = `data:image;base64,${response.imgContent}`;
          user.hasAccountProfilePicture = true;
        }
      });

    user.tokenSecurityService = tokenSecurityService;
    user.profilePicture = profilePicture ? profilePicture : "https://www.pngfind.com/pngs/m/110-1102775_download-empty-profile-hd-png-download.png";
    user.socialType = options.socialType;

    let session = {
      user,
      tokenSecurityService,
      menu
    }
    let config = null;

    if (options.noRedirect) {
      config = {
        noRedirect: options.noRedirect
      }
    }

    return _afterLogin(Object.assign({}, session), config)

  },
  setCPF(cpf) {
    let session = this.getSession();

    if (session && session.user) {
      session.user.cpf = cpf;
      this.setUser(session.user);
    }
  },
  logout() {
    Sentry.configureScope(scope => scope.setUser(null));
    _logout()
  },
  externalLogin(session, config) {
    return _afterLogin(Object.assign({}, session), config)
  },
  SESSION_STORAGE_KEY: SESSION_STORAGE_KEY,
  TERM_FLAG,
  install(Vue, config) {
    _vm = config.vm

    Vue.prototype.$auth = this
  }
}

const _afterLogin = (session, config) => {

  session.createDate = new Date().getTime()

  _vm.$auth.setSession(session);

  var redirectTo = 'root';
  if (config && config.redirectTo != undefined)
    redirectTo = config.redirectTo;

  if (config && config.noRedirect)
    redirectTo = null;

  if (redirectTo) {
    _vm.$util.link(redirectTo);
    _vm.$store.commit('refreshReloadableKey');
  }

  return session
}

const _logout = () => {

  try{
    logoutSession();
  }catch (error){
    // vazio
  }

  sessionStorage.clear();
  localStorage.removeItem(SESSION_STORAGE_KEY);
  _vm.$cookies.remove(TERM_FLAG);
  _vm.$util.link("logout");
  _vm.$store.commit('user', {});
}
