<template>
  <v-snackbar
      v-if="msg.enabled"
      v-model="msgshow"
      color="#c9aad1"
      :timeout="msg.timeout"
      top
      class="subtitle-1"
      content-class="font-weight-bold"
      :key="msg.reloadableKey"
  >
    <span style="color: #532E88;">{{ msg.data.text }}</span>
    <v-btn text @click="hidemsg()">
      <v-icon color="#532E88" rigth>fas fa-times</v-icon>
    </v-btn>
    <v-style v-if="menu.toolbar && msgshow">
      .v-snack--top{ top: calc(8px + {{ app.structure.heightToolbar }})
      !important; }
    </v-style>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(["msg", "menu", "app"]),
    msgshow: {
      get: function() {
        return this.msg.show;
      },
      set: function(value) {
        this.tooglemsg(value);
      }
    }
  },
  methods: {
    ...mapMutations(["tooglemsg", "hidemsg"])
  }
};
</script>
