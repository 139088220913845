import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// eslint-disable-next-line no-undef
const merge = require('deepmerge')

import app from './app.js';
import chat from './chat.js';
import msg from './msg.js';
import menu from './menu.js';
import window from './window.js';
import badge from './badge.js';
import filters from './filters.js';
import session from "./user";

const mainStore = {
    strict: true,
}

const finalStore = merge.all([
    mainStore,
    app,
    chat,
    msg,
    menu,
    window,
    badge,
    session,
    filters
])

export default new Vuex.Store(finalStore)
