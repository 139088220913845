import $auth from "../common/auth";

export default {
    state: {
        user: { },
    },
    mutations: {
        user(state, user) {
            state.user = user;
        },
        profilePicture(state, profilePicture) {
            state.user.profilePicture = profilePicture;
        },
    },
    getters: {
        getPicture: (state) => state.user.profilePicture,
        user: (state) => state.user,
    },
    actions: {
        changeProfilePicture(context, data){
            let session = $auth.getSession();
            session.user.profilePicture = data;
            $auth.setSession(session);
            context.commit('profilePicture',data);
        }
    }
}
