/* eslint-disable */
import HttpService from '../HttpService';
export default class SocialNetworkService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`socialnetwork`);
  }

  FindFeedItemUser(idUser, page, beneficiarySituation ) {
    return this._httpService.get(`/feed_item/findFeedItemUser?idUser=${idUser}&size=3&page=${page}&beneficiarySituation=${beneficiarySituation}`);
  }

  countFeedsByEntity(beneficiarySituation) {
    return this._httpService.get(`/feed_item/countFeedsByEntity?entityId=0&onlyNew=true&parentId=0&type=POST&beneficiarySituation=${beneficiarySituation}`);
  }

  FindFeedItemUserByParams(idUser, page, type, onlyNew, beneficiarySituation) {
    return this._httpService.get(`/feed_item/findFeedItemUser?idUser=${idUser}&size=3&page=${page}&type=${type}&onlyNew=${onlyNew}&beneficiarySituation=${beneficiarySituation}`);
  }

  FindFeedItemUserByEntity(idUser, entityId, beneficiarySituation) {
    return this._httpService.get(`/feed_item/findFeedItemUser?idUser=${idUser}&size=99&page=0&onlyNew=true&entityId=${entityId}&beneficiarySituation=${beneficiarySituation}`);
  }

  getEntityByType(type) {
    return this._httpService.get(`/entity/getEntityByType/${type}`);
  }

  FindEntityById(id) {
    return this._httpService.get(`/entity/${id}`);
  }

  async MarkReadByMessageId(messageId) {
    return await this._httpService.get('/user_push_notification_messages/markReadByMessageId', {messageId});
  }

  FindBookmarkItemUser(idUser, beneficiarySituation) {
    return this._httpService.get(`/feed_item/findFeedItemUser?idUser=${idUser}&size=99&page=0&bookmarked=true&beneficiarySituation=${beneficiarySituation}`);
  }

  GetMidiaFeed(idFeed) {
    return this._httpService.get(`/media/findMediaFeed/${idFeed}`);
  }

  SaveLike(data) {
    return this._httpService.post("/feed_item_like/like", data);
  }

  findFeedItemUserAdmin(idUser, parentId, page) {
    return this._httpService.get(`/feed_item/findFeedItemUserAdmin?idUser=${idUser ? idUser : ''}&size=25&page=${page}&parentId=${parentId}`);
  }

  findFeedItemUserAdminFilter(params) {
    return this._httpService.get(`/feed_item/findFeedItemUserAdminFilter${params}`);
  }

  getFeedId(idFeed) {
    return this._httpService.get(`/feed_item/${idFeed}`);
  }

  SavePost(data) {
    return this._httpService.post("/feed/save", data);
  }

  getEntityListPerUser(idUser) {
    return this._httpService.get(`/entity/getEntityPerUser/${idUser ? idUser: 0}`);
  }

  listAllChatBotEntity() {
    return this._httpService.get(`/entity/findAllChatBot`);
  }

  findTargetByFeed(idFeed, page) {
    return this._httpService.get(`/target/findTargetByFeed?idFeed=${idFeed}&size=10&page=${page}`);
  }

  findTargetByFeedFilter(params) {
    return this._httpService.get(`/target/findTargetByFeedFilter${params}`);
  }

  getTarget(idTarget) {
    return this._httpService.get(`/target/${idTarget}`);
  }

  getTargetTypeList() {
    return this._httpService.get(`/target/getTargetType`);
  }

  UpdateTarget(data) {
    return this._httpService.put(`/target/${data.id}`, data);
  }

  SaveTarget(data) {
    return this._httpService.post(`/target/save`, data);
  }

  SaveBookmark(data) {
    return this._httpService.post("/feed_item_bookmark/save", data);
  }

  FindFeedItemBookmark(idUser) {
    return this._httpService.get(`/feed_item_bookmark/findFeedItemBookmark?idUser=${idUser}`);
  }

  SaveViewStorie(data) {
    return this._httpService.post("/feed_item_view/save", data);
  }

  async FindTips() {
    return await this._httpService.get("/feed_item/findTips");
  }

  async FindMediaTips(tipId){
    return await this._httpService.get("/media/findMediaFeed/" + tipId)
  }
}

