<template>
  <v-container class="bg-effect" style="height: 90vh">
    <v-row class="mt-1">
      <v-btn icon color="primary" @click="onClickBack()">
        <v-icon>fas fa-chevron-left</v-icon>
      </v-btn>
    </v-row>
    <v-row class="mt-8">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">Detalhes do Usuário</p>
      </v-col>
      <v-col cols="12">
        <v-form class="pa-0 ma-0" ref="confirmForm">
          <v-row>
            <v-col cols="6">
              <v-text-field
                class="mb-2"
                label="Grupo Financeiro"
                v-model="user.financialGroupName"
                readonly
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                class="mb-2"
                label="Aṕolice"
                v-model="user.policy"
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-text-field
                class="mb-2"
                label="Subcontrato"
                readonly
                v-model="user.subcontractName"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                class="mb-2"
                label="Matrícula"
                v-model="user.registration"
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                class="mb-2"
                label="Nome do Titular"
                v-model="user.holder"
                readonly
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                class="mb-2"
                label="Nome"
                v-model="user.name"
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="mb-2"
                label="CPF"
                v-mask="['###.###.###-##']"
                v-model="user.cpf"
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Data de Conexão"
                placeholder="Data de Conexão"
                readonly
                :value="user.connectCompanyAcceptDate"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Último Acesso"
                placeholder="Último Acesso"
                readonly
                v-model="user.lastAccessDate"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackArrow from "../../components/covid/back-arrow";
import { mapMutations } from "vuex";

export default {
  name: "userDetail",
  component: {
    BackArrow,
  },
  data: () => ({
    user: {
      login: null,
      name: null,
      cpf: null,
      kinship: null,
      financialGroupId: null,
      financialGroupName: null,
      policy: null,
      subcontractId: null,
      subcontractName: null,
      registration: null,
      holder: null,
      connectCompanyAcceptDate: null,
      lastAccessDate: null,
      status: null,
    },
  }),
  async created() {
    this.loadUser();
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    loadUser() {
      let user = this.$route.params.user;
      this.user = user;

      if (this.$route.params.user.lastAccessDate) {
        let lastAcessDate = this.$route.params.user.lastAccessDate.split("T");
        this.user.lastAccessDate = `${this.formatDate(lastAcessDate[0])} ${this.formatTime(lastAcessDate[1])}`;
      }

      if (this.$route.params.user.connectCompanyAcceptDate) {
        let connectCompanyAcceptDate = this.$route.params.user.connectCompanyAcceptDate.split("T");
        this.user.connectCompanyAcceptDate = `${this.formatDate(connectCompanyAcceptDate[0])} ${this.formatTime(connectCompanyAcceptDate[1])}`;
      }
    },
    formatDate(date) {
      let formattedDate = date.split("-");
      return `${formattedDate[2]}/${formattedDate[1]}/${formattedDate[0]}`;
    },
    formatTime(time) {
      let formattedTime = time.split(".");
      return formattedTime[0];
    },
    onClickBack() {
      this.$router.push({ name: "users-view" });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-effect {
  background: rgba(255, 255, 255, 0.55);
  mix-blend-mode: normal;
  padding: 10px;
}
</style>
