var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-3 pt-16 bg-effect",staticStyle:{"height":"100%"}},[_c('Totalizer',{staticClass:"mb-2",attrs:{"totals":_vm.totals,"title":_vm.title,"color":"#532E88"}}),_c('v-row',[_c('v-col',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-card',{staticClass:"option-list-card list-card mb-6",staticStyle:{"background":"rgba(231, 234, 254, 0.6)"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pl-6 pr-0 pt-5",attrs:{"cols":"7"}},[_c('div',{class:!_vm.activeFilter ? 'list-card pt-3' : 'pb-1 pt-3',style:(!_vm.activeFilter && 'background: white; height: 50px')},[_c('p',{staticClass:"text-center generic-text mb-0",staticStyle:{"font-size":"16px !important","font-weight":"bolder !important"},on:{"click":function () { return (_vm.activeFilter = !_vm.activeFilter); }}},[_vm._v(" Conectados ")])])]),_c('v-col',{staticClass:"pl-0 pr-7 pt-5 mt-0",attrs:{"cols":"5"}},[_c('div',{class:_vm.activeFilter ? 'list-card pt-3' : 'pb-1 pt-3',style:(_vm.activeFilter && 'background: white; height: 50px')},[_c('p',{staticClass:"text-center generic-text mb-0",staticStyle:{"font-size":"16px !important","font-weight":"bolder !important","height":"50% !important"},on:{"click":function () { return (_vm.activeFilter = !_vm.activeFilter); }}},[_vm._v(" Não Conectados ")])])])],1)],1)],1),_c('v-row',{staticClass:"mb-3 mx-0 justify-end"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Filtrar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fas fa-filter")])],1)]}}]),model:{value:(_vm.filterDialog),callback:function ($$v) {_vm.filterDialog=$$v},expression:"filterDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary lighten-4 white--text"},[_vm._v(" Filtrar por: ")]),_c('v-card-text',[_c('v-autocomplete',{staticClass:"mt-4",attrs:{"item-text":"name","item-value":"id","items":_vm.financialGroups,"label":"Grupo Financeiro","placeholder":"Selecione o grupo financeiro"},model:{value:(_vm.filter.financialGroup),callback:function ($$v) {_vm.$set(_vm.filter, "financialGroup", $$v)},expression:"filter.financialGroup"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.clearFilters()}}},[_vm._v(" Limpar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.filterUsers()}}},[_vm._v(" Filtrar ")])],1)],1)],1)],1),_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[(!_vm.activeFilter)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.connectedUsers,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Nenhum usuário conectado encontrado ")]},proxy:true},{key:"item.financialGroupName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.financialGroupName ? item.financialGroupName : "-")+" ")]}},{key:"item.policy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.policy ? item.policy : "-")+" ")]}},{key:"item.subcontractName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subcontractName ? item.subcontractName : "-")+" ")]}},{key:"item.registration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.registration ? item.registration : "-")+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name ? item.name : "-")+" ")]}},{key:"item.holder",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.holder ? item.holder : "-")+" ")]}},{key:"item.attachments",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.details(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Detalhar")])])]}}],null,true)}),_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.params.totalPages,"total-visible":"10"},on:{"input":function($event){return _vm.loadUsers()}},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}})],1)],1):_vm._e(),(_vm.activeFilter)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.nonConnectedUsers,"items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Nenhum usuário desconectado encontrado ")]},proxy:true},{key:"item.financialGroupName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.financialGroupName ? item.financialGroupName : "-")+" ")]}},{key:"item.policy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.policy ? item.policy : "-")+" ")]}},{key:"item.subcontractName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subcontractName ? item.subcontractName : "-")+" ")]}},{key:"item.registration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.registration ? item.registration : "-")+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name ? item.name : "-")+" ")]}},{key:"item.holder",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.holder ? item.holder : "-")+" ")]}},{key:"item.attachments",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.details(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Detalhar")])])]}}],null,true)}),_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.params.totalPages,"total-visible":"10"},on:{"input":function($event){return _vm.loadUsers()}},model:{value:(_vm.params.page),callback:function ($$v) {_vm.$set(_vm.params, "page", $$v)},expression:"params.page"}})],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }