const SESSION_TOKEN = 'token';

export default {
  name: 'Store',

  saveToken(token) {
    localStorage.setItem(SESSION_TOKEN, token);
  },

  getToken() {
    return localStorage.getItem(SESSION_TOKEN);
  },
};
