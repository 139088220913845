export default {
  state: {
    window: {
      width: 0,
      height: 0,
      mobileSize: 960,
      isBottomScrool: false,
    },
  },
  getters: {
    isMobileSize: (state) => state.window.width <= state.window.mobileSize
  },
  mutations: {
    handleResize(state) {
      state.window.width = window.innerWidth;
      state.window.height = window.innerHeight;
    },
    handleScroll(state) {
      // HANDLE SCROLL END
      state.window.isBottomScrool = (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight) >= (document.documentElement.offsetHeight - 100)
    },
  }
}