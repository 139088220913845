import axios from 'axios';
import $auth from "./auth";

const {
    VUE_APP_API_URL
// eslint-disable-next-line no-undef
} = process.env;

const VUE_APP_CONTRACT_SERVICE_URL = VUE_APP_API_URL + "/contract/"

const loadDependentsByBeneficiaryCpf = (cpf) => {
    const url = `${VUE_APP_CONTRACT_SERVICE_URL}`  + 'dependent/loadDependentsPhysicalPersonAndInvitationByBeneficiaryCpf/' + cpf;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const existsBeneficiaryByCpf = (cpf) => {
    const url = `${VUE_APP_CONTRACT_SERVICE_URL}`  + 'beneficiary/existsByCpf/' + cpf;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const existsDependentByCpf = (cpf) => {
    const url = `${VUE_APP_CONTRACT_SERVICE_URL}`  + 'dependent/existsByCpf/' + cpf;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
    });
};

const beneficiaryById = (id, token) => {
    const url = `${VUE_APP_CONTRACT_SERVICE_URL}`  + 'beneficiary/findBeneficiaryAndContractedPlanByIdBeneficiary/' + id;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    });
};

export {
    loadDependentsByBeneficiaryCpf,
    existsBeneficiaryByCpf,
    existsDependentByCpf,
    beneficiaryById
};
