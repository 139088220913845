import {
  formmasks
} from './formmasks'

const formrules = {
  required: value => !!value || "Campo obrigatório.",
  celular: value => {
    var isvalid = false;
    if (value) {
      const match = formmasks.unmask(value).match(/\d/g);
      if (match)
        isvalid = match.length >= 8
    }

    return isvalid || "Telefone inválido"
  },
  ddd: value => {
    var isvalid = false;
    if (value) {
      const match = formmasks.unmask(value).match(/\d/g);
      if (match)
        isvalid = match.length == 2
    }

    return isvalid || "DDD inválido"
  },
  countrycode: value => {
    var isvalid = false;
    if (value) {
      const match = formmasks.unmask(value).match(/\d/g);
      if (match)
        isvalid = match.length == 2
    }

    return isvalid || "Código do País inválido"
  },
  range1a100: value => {
    return _range(value, 1, 100);
  },
  creditCardRequired: (value) => (value && value.length === 19 || 'Campo obrigatório.'),
  creditCardSecurityCodeRequired: (value) => (value && value.length === 3 || 'Campo obrigatório.'),
  creditCardEndDate: (value) => {
    if(value && value.length === 5) {
      const valueParts = value.split('/');
      const actualMonth = new Date().getMonth() + 1;
      const actualYear = new Date().getFullYear();
      const stringActualYear = actualYear.toString().substring(2, actualYear.toString().length)

      const mounth = parseInt(valueParts[0]);
      const year = parseInt(valueParts[1]);
      if (mounth <= 12 && ((mounth >= actualMonth && year >= parseInt(stringActualYear)) || (mounth < actualMonth && year > parseInt(stringActualYear)))) {
        return true;
      } else {
        return 'Data inválida.'
      }
    } else {
      return 'Campo obrigatório.'
    }
  }
}

const _range = (value, min, max) => {
  var isvalid = false;
  var msg = "Valor inválido";

  if (value) {
    if (!(value >= min)) {
      msg = "Preencha com algo maior que " + min
    } else if (!(value <= max)) {
      msg = "Preencha com algo menor que " + max
    }else isvalid = true
  }

  return isvalid || msg
}

export {
  formrules
}