<template>
  <GenericFabBottomSheet
          ref="genericFabBottomSheet"
          :title="title"
          icon="fas fa-filter"
          @open="toggleHabilityToSearch(false)"
          @close="toggleHabilityToSearch(true)"
          @clearFilters="clearFilters"
  >
    <GenericFilterer
            ref="genericFilterer"
            :filters="filters"
            :data="data"
            :baseQuery="baseQuery"
            @queryUpdate="_ => $emit('queryUpdate', _)"
            @filterChange="emitData"
            @filtersRemoved="_ => $emit('filtersRemoved', _)"
    />
  </GenericFabBottomSheet>
</template>

<script>
import GenericFabBottomSheet from "@/components/generic-fab-bottom-sheet";
import GenericFilterer from "@/components/generic-filterer";

export default {
  name: "FabFilterer",
  components: { GenericFabBottomSheet, GenericFilterer },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    baseQuery: {
      type: String,
      required: false,
    },
    title:{
      type: String,
      required: false,
      default: 'Filtrar',
    }
  },
  methods: {
    emitData(dataToEmit) {
      this.$emit('filterChange', dataToEmit);
    },
    toggleHabilityToSearch(value) {
      this.$store.commit('setAbledToSearch', value);
    },
    clearFilters() {
      this.$refs.genericFilterer.clearFilters();
      this.$emit('clearFilters');
    }
  },
};
</script>
