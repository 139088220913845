<template>
  <v-row class="py-0 mr-1">
    <v-col cols="3" class="py-5" >
      <v-subheader>{{appConfiguration.title }}
        <v-tooltip top color="rgb(84,79,105)" v-if="appConfiguration.tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                color="gray"
                v-bind="attrs"
                v-on="on"
                class="pl-2"
            >
              fas fa-info-circle
            </v-icon>
          </template>
          <span>{{appConfiguration.tooltip}}</span>
        </v-tooltip>
      </v-subheader>

    </v-col>
    <v-col cols="8" class="py-5">
      <v-text-field :disabled="!editing" v-if="appConfiguration.fieldType == 'TEXT'" v-model="appConfiguration.value" :outlined="editing" dense />
      <v-text-field :disabled="!editing"  v-else-if="appConfiguration.fieldType == 'NUMBER'" v-model.number="appConfiguration.value" :outlined="editing" ense />
      <v-checkbox :disabled="!editing" v-else-if="appConfiguration.fieldType == 'CHECKBOX'" v-model="checkValue"/>
      <v-select :disabled="!editing" v-else-if="appConfiguration.fieldType == 'SELECT'" :items="appConfiguration.options" v-model="appConfiguration.value" :outlined="editing"  dense />
      <v-select :disabled="!editing" v-else-if="appConfiguration.fieldType == 'COMBO'"  multiple :items="appConfiguration.options" v-model="appConfiguration.value" :outlined="editing"  dense />
      <date-picker :disabled="!editing" v-else-if="appConfiguration.fieldType == 'DATE'" :date-prop="appConfiguration.value" v-on:updateDate="updateDate"/>
    </v-col>
    <v-col cols="1">
      <v-icon
          v-show="!editing"
          small
          color="gray"
          class="pl-2"
          @click="startEdit()"
      >
        fas fa-pencil-alt
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>

import DatePicker from '../../components/date-picker/index.vue';
export default {
  name: "configField",
  components: {
    DatePicker
  },
  props: {
    appConfiguration :{
      type: Object
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checkValue: {
      get () {
        return JSON.parse(this.appConfiguration.value);
      },
      set (val) {
        this.appConfiguration.value = val;
      },
    }
  },
  methods: {
    startEdit(){
      this.$emit('startEdit');
    },
    updateDate(date){
      if(this.appConfiguration.fieldType == 'DATE'){
        this.appConfiguration.value = date;
      }
    },
  },
  created() {

    if(this.appConfiguration.fieldType == 'COMBO' || this.appConfiguration.fieldType == 'SELECT' ){
      this.appConfiguration.options = this.appConfiguration.optionValues ? this.appConfiguration.optionValues.split(",") : [];
    }
    if(this.appConfiguration.fieldType == 'COMBO'){
      this.appConfiguration.value = this.appConfiguration.value ? this.appConfiguration.value.split(",") : [];
    }
  },

}
</script>

<style scoped>

</style>
