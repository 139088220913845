export default {
  state: {
    badges: {
      avisos: 2,
      carrinho: 0
    }
  },
  mutations: {
    updateBadges(state, badges){
      state.badges = Object.assign(state.badges, badges);
    },
  }
}