<template>
  <v-btn
    v-bind="btnProps"
    :color="color"
    :class="defaultTheme ? 'btn-theme' : 'capitalizeFont'"
    :style="btnProps.text || { color: getBtnColor() }"
    @click="onClick && onClick()"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'generic-btn',
  props: {
    btnProps: {
      required: true,
      type: Object
    },
    color: {
      default: 'primary',
      type: String
    },
    onClick: {
      required: false,
      type: Function
    },
    defaultTheme: {
      default: true,
      type: Boolean
    }
  },
  methods:{
    getBtnColor(){
      if(this.color == 'primary'){
        return 'white'
      }if(this.color == 'secondary'){
        return 'var(--v-primary)'
      }if(this.color == 'tertiary'){
        return 'black'
      }
      return null
    }
  }
}

</script>

<style scoped>
  .btn-theme {
    border-radius: 20px !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 27px !important;
    height: 56px !important;
    text-transform: capitalize !important;
  }

  .capitalizeFont {
    text-transform: initial !important;
  }
</style>