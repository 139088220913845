import axios from 'axios';
import $auth from '@/common/auth';

export default class HttpService {
    constructor(serviceURL) {
        this.ws = this._loadAxios(`${ process.env.VUE_APP_API_URL }/${serviceURL}`);
    }

    _loadAxios(serviceURL) {
        return axios.create({
            baseURL: serviceURL,
            headers: this._loadHeaders(),
        });
    }

    _loadHeaders() {
        let token_active = $auth.getSession();

        let current_token = (token_active
            && token_active.tokenSecurityService
            && token_active.tokenSecurityService.access_token) || $auth.getLocalizedToken();

        return {
            Authorization: `Bearer ${current_token}`,
            'Access-Control-Max-Age': 600,
            'Cache-Control': 'max-age=600'
        }
    }

    get(path, params = null) {
        return this.ws.get(path, {
            params: params,
        });
    }

    post(path, data) {
        return this.ws.post(path, data);
    }

    put(path, data) {
        return this.ws.put(path, data);
    }

    delete(path, params = null) {
        return this.ws.delete(path, {
            params: params,
        });
    }

    getBlob(path) {
        // this.ws = this._loadAxios(path, "blob" )
        return this.ws.get(path, {responseType: "blob"});
    }

    all(path, params){
        const requests = [];
        params.forEach(param =>{
            requests.push(this.get(path, {
                [param.label] : param.value,
            }))
        })

        return axios.all(requests);
    }
}
