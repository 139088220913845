<template>
  <v-app id="eu_protegido" :style="cssTheme" :class="bgColor">
    <template>
      <msg />
      <Loading />
      <router-view name="container" :key="$route.fullPath"></router-view>
      <keep-alive>
        <router-view name="containerkeepalive"></router-view>
      </keep-alive>
<!--      <version-update />-->
    </template>
  </v-app>
</template>

<script>
import msg from "./components/structure/msg.vue";
import { mapMutations } from "vuex";
import Loading from "./components/structure/Loading";
//import VersionUpdate from "./components/structure/VersionUpdate";
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import UserService from "@/services/security/User";
import MenuService from "@/services/security/Menu";
import {NativeBiometric} from "capacitor-native-biometric";

export default {
  data: () => ({
    backButtonCount: 0,
  }),
  methods: {
    ...mapMutations(["updateMenuState", "updateStructure", "loading", "enableMsg", "showmsg"]),
    initBackButton() {
      if(Capacitor.isNativePlatform) {
        CapacitorApp.addListener('backButton', ({canGoBack}) => {

          if(!canGoBack){
            this.verifyBackButtonAndCloseApp();
          } else {
            if(this.$route.path === '/home') {
              this.verifyBackButtonAndCloseApp();
            }else {
              this.$router.back();
            }
          }

        });
      }
    },
    verifyBackButtonAndCloseApp() {
      if(this.backButtonCount < 1) {
        this.showmsg({text: "Pressione novamente para sair.", type: "error" });
      } else {
        CapacitorApp.exitApp();
      }
      this.backButtonCount++;
    },
    initBiometricNative() {
      const biometricKey = 'BIOMETRIC';
      const biometricCanceledKey = 'LOGIN_BIOMETRIC_CANCELED';

      if(sessionStorage.getItem(biometricKey)){
        const biometricData = JSON.parse(atob(sessionStorage.getItem('BIOMETRIC')));
        const serverOrigin = window.location.origin;

        NativeBiometric.deleteCredentials({
          server: serverOrigin,
        }).finally(() => {
          NativeBiometric.setCredentials({
            username: biometricData.email,
            password: btoa(biometricData.password),
            server: serverOrigin,
          }).then().finally(() => {
            sessionStorage.removeItem(biometricKey);
          });
        });
      }

      if(sessionStorage.getItem(biometricCanceledKey)) {
        sessionStorage.removeItem(biometricCanceledKey);
      }
    },
    initialize() {
      this.checkAppInstalationStatus();
      let session = this.$auth.getSession();
      if (session && session.user) this.$store.commit("user", session.user);
    },
    checkSecurity() {
      if (this.$util.checkIndex())
        this.$auth.logout();
      else
        this.$auth.checkAuthentication();
    },
    checkAppInstalationStatus() {
      const isInstalled = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
      const { skipInstall } = this.$route.meta;
      if (isInstalled || process.env.NODE_ENV === 'development' || skipInstall)
      {
        this.checkSecurity();
        return;
      }

      if(Capacitor.isNativePlatform()) {
        this.checkSecurity();
      } else {
        const path = this.$route.path;
        if(path !== '/install') {
          this.$router.push('/install');
        }
      }
    },
    async reloadSessionByToken(){
      const token = this.$auth.getAccessToken()
      const cpf = this.$auth.user().cpf;
      const hasCompanyConnection = this.$auth.user().hasCompanyConnection;
      await this._userService.reloadSessionByToken(token)
          .then(response => {
            if(response && response.data && response.data.user) {
              this.$auth.setUser(response.data.user);
              this.$auth.setCPF(this.$util.removerPontuacaoCPF(cpf));
              if(hasCompanyConnection !== response.data.user.hasCompanyConnection) {
                this.$router.push({ name: 'conectarComSuaEmpresa', query: { receiveConnection: true } })
              }
            }
          });
    },
    reloadMenus(){
      this._menuService.LoadMenu()
          .then((response) => {
            if(response.data){
              let session = this.$auth.getSession()
              session.menu = response.data
              this.$auth.setSession(session)
            }
          });
    },
    async verifyHasConnectionWithCompany(){
      if(this.$auth.user() && !this.$auth.user().hasCompanyConnection){
        await this.reloadSessionByToken();
        await this.reloadMenus();
      }
    },
    setStatusSnackbarMsgBySession() {
      if(localStorage.getItem('SESSION')) {
        this.enableMsg(true)
      } else {
        this.enableMsg(false)
      }
    },
    existsAssessmentMandatory() {
      const tagAssessmentMandatory = 'assessmentMandatoryCompleted';

      // if(!sessionStorage.getItem(tagAssessmentMandatory) && this.$auth.user() && this.$auth.user().assessmentMandatoryFilter &&
      //     ((this.$auth.user().assessmentMandatoryFilter.contractIds && this.$auth.user().assessmentMandatoryFilter.contractIds.length)
      //     || (this.$auth.user().assessmentMandatoryFilter.subContractIds && this.$auth.user().assessmentMandatoryFilter.subContractIds.length))) {
      //   this.$router.push({name: 'assessment', query: { assessmentMandatory: true } });
      // }
      if(!sessionStorage.getItem(tagAssessmentMandatory)) {
        this.$router.push({name: 'assessment', query: { assessmentMandatory: true } });
      }
    }
  },
  watch: {
    $route() {
      this.setStatusSnackbarMsgBySession();
      this.checkAppInstalationStatus();

      const path = this.$route.path;
      const meta = this.$route.meta;
      if(path && path !== '/login' && path !== '/install') {
        this.$auth.resetActiveDate(false, meta, path);
        this._userService = new UserService();
        this._menuService = new MenuService();
        if(!meta.skipInstall) {
          this.verifyHasConnectionWithCompany();
        }
      }

      if(path && path === '/home') {
        this.initBiometricNative();
      }

      if(path && path !== '/login' && path !== '/install'
          && path !== '/assessment' && !path.includes('/assessment-answer')) {
        this.existsAssessmentMandatory();
      }
    },
  },

  created() {
    this.checkSessionTokenExists = window.location.search.split('token=').length > 1;
    this.initBackButton();
  },
  computed: {
    cssTheme() {
      // espaço da barra de menu inferior
      const heightBottom = this.$util.isIosMobile()
          ? "75px"
          : this.$util.isMobile()
              ? "70px"
              : "70px"; //web version
      const paddingBottom = this.$util.isIosMobile() ? "5px" : "0px";
      this.updateStructure({ heightBottom, paddingBottom });

      const out = {};
      out["--v-heightBottom"] = heightBottom;
      out["--v-paddingBottom"] = paddingBottom;

      for (const name of Object.keys(this.$vuetify.theme.themes.light))
        out["--v-" + name] = this.$vuetify.theme.themes.light[name];

      if(Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
        out.paddingTop = '5vh !important'
      }

      return out;
    },

    bgColor() {
      return this.$route.meta.classMainContainer;
    }
  },
  components: { /*VersionUpdate,*/ Loading, msg },
};
</script>

<style>
/* Workarround https://github.com/vuetifyjs/vuetify/issues/13599 */
.v-btn--disabled {
  pointer-events: unset !important;
  cursor: default;
}

.v-input--is-disabled .v-input__append-inner {
  display: none;
}
</style>

<style lang="scss">
@import "public/styles/app.scss";

/* utils */
.clearBoth {
  clear: both;
}
.v-application {
  //background-color: var(--v-appbgcolor) !important;
  background: var(--v-appgradientcolor) !important;
}

.v-dialog:not(.v-dialog--fullscreen) {
  border-radius: 40px !important;
}

.empty-background{
  background-color: white !important;
}

</style>
