<template class="container-background">
  <v-container fluid style="background-color: white">
    <v-row>
      <v-col v-for="group in groups" :key="group" cols="12">
        <v-subheader >{{group}}</v-subheader>
        <v-row no-gutters>
          <v-col
              v-for="appConfiguration in configurationsMap.get(group)"
              :key="appConfiguration.key"
              cols="12"
              lg="6"

          >
            <config-field :app-configuration="appConfiguration" :editing="editing" v-on:startEdit="startEdit" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import ConfigField from "@/view/config-app/appConfigurationField";

export default {
  name: "configFinancialGroup",
  components: {ConfigField},
  data: () => ({
    token: "",
    entityID: "",
    entityType: "",

    groups: [],
    configurationsMap: new Map(),
  }),
  props:{
    editing: {
      type: Boolean,
      default: false
    },
    entityConfigurations: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    startEdit(){
      this.$emit('startEdit');
    },
    loadConfigurations(){
      this.entityConfigurations.forEach(configuration => {
        let configurations = this.configurationsMap.get(configuration.group);
        if(configurations){
          configurations = [...configurations, configuration];
        }else{
          configurations = [configuration];
          this.groups.push(configuration.group);
        }
        this.configurationsMap.set(configuration.group, configurations);
      });
    }
  },
  created() {
      this.loadConfigurations();
  },

}
</script>

<style scoped>

</style>