/* eslint-disable */
import HttpService from '../HttpService';
export default class MenuService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService('security/menu');
  }

  async LoadMenu() {
    return await this._httpService.get('/loadMenu');
  }
}

