export default {
  state: {
    menu: {
      left: false,
      leftmini: false,
      rigth: false,
      toolbar: false,
      menuMobile: false,
      toolbarBehavior: false,
      showtour: false,
      hamburguer: false,
      screenshotImage: '',
      tour: {
        tourOptions: {
          labels: {
            buttonSkip: "Finalizar",
            buttonPrevious: "Anterior",
            buttonNext: "Próximo",
            buttonStop: "Finalizar",
          },
        },
      },
      reloadableKey: new Date().getTime()
    },
  },
  mutations: {
    refreshReloadableMenuKey(state) {
      state.menu.reloadableKey = new Date().getTime()
    },
    refreshScreenshot(state, image) {
      state.menu.screenshotImage = image;
    },
    updateMenuState(state, menu) {
      state.menu = Object.assign(state.menu, menu);
    },
    toggleMenuMob(state) {
      state.menu.menuMobile = !state.menu.menuMobile;
    },
    toggleMenul(state) {
      if (this.getters.isMobileSize) {
        state.menu.left = !state.menu.left
      } else {
        state.menu.leftmini = !state.menu.leftmini
      }
    },
    toggleMenur(state) {
      state.menu.rigth = !state.menu.rigth
    },
    toggleShowTour(state) {
      state.menu.showtour = !state.menu.showtour
    },
    toggleShowHamgurber(state, situacao) {

      document.getElementById('mainView').style.overflowY = situacao ? 'hidden' : 'visible'

      state.menu.hamburguer = situacao;
    },
  },
}